
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    link: {
      type: String,
      required: true,
    },
    title: String,
    noclose: Boolean,
  },
  setup(props) {
    const store = useStore();

    const isVisible = computed(() => store.state.modalstate[props.link]);
    const hideModal = () => (store.state.modalstate[props.link] = false);

    return {
      props,
      isVisible,
      hideModal,
    };
  },
});
